import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import RichText from '../components/richtext';
import { media } from '../utils/media-queries';

const LegalNoticePage = () => {
  const data = useStaticQuery(graphql`
    query LegalNotice {
      legalNotice: allSanityLegalNotice {
        edges {
          node {
            title
            _rawContent
          }
        }
      }
    }
  `);

  const legalNotice = data.legalNotice?.edges.map(edge => edge.node);

  return (
    <Layout color="white">
      <SEO siteTitle="impressum" />
      <StyledLegal>
        <RichText blocks={legalNotice[0]._rawContent} />
      </StyledLegal>
    </Layout>
  );
};

const StyledLegal = styled.section`
  padding: var(--spacing-XS) var(--spacing-XXS);
  font-size: var(--fontsize-0);

  @media ${media.M} {
    overflow-x: scroll;
    height: 80vh;
    padding: 0;
    padding-right: var(--spacing-XXS);
  }
`;

export default LegalNoticePage;
